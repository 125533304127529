import Vue from 'vue'
import VueRouter from 'vue-router'

import { auth } from '@/firebase/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: "Default",
    component: () => import('@/layouts/DefaultLayout.vue'),
    meta: { requiresAuth: true },
    redirect: () => {
      return 'dashboard'
    },
    children: [
      {
        path: '/dashboard',
        name: "Dashboard",
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/dealers',
        name: "Dealers",
        component: () => import('@/views/Dealers.vue'),
      },
      {
        path: '/payments',
        name: "Payments",
        component: () => import('@/views/Payments.vue'),
      },
      {
        path: '/invoices',
        name: "Invoices",
        component: () => import('@/views/Invoices.vue'),
      },
      {
        path: '/summaries',
        name: "Summaries",
        component: () => import('@/views/Summaries.vue'),
      },

      {
        path: '/products',
        name: "Products",
        component: () => import('@/views/Products.vue'),
      },
      {
        path: '/populate',
        name: "Populate",
        component: () => import('@/views/Populate.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: "Login",
    component: () => import('@/layouts/LoginLayout.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = auth.currentUser;
  if (requiresAuth && !isAuthenticated) {
    next("/login")
  } else {
    next()
  }
})

export default router
