import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { auth } from './firebase/index'
import { useAppStore } from '@/store/app'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueApexCharts from 'vue-apexcharts'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

Vue.use(VueGoogleMaps, {
  load: {
    // romesh.etu@gmail.com    project: My First Project (decisive-clover-403303)
    key: process.env.VUE_APP_MAP_API_KEY,
  },
})

var app;
auth.onAuthStateChanged(user => {
  if (app) {
    app.$destroy()
  }

  app = new Vue({
    router,
    vuetify,
    pinia,
    render: h => h(App)
  }).$mount('#app')

  const store = useAppStore()

  if (user) {
    store.loginUser(user)
  } else {
    store.logoutUser()
  }
})
