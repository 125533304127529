// Utilities
import { defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  state: () => ({
    user: null,
  }),

  getters: {},

  actions: {
    loginUser(user) {
      this.user = user
    },

    logoutUser() {
      this.user = null
    },
  }
})
